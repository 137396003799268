import { Elements } from '@stripe/react-stripe-js';
import CardSetupForm from './CardSetupForm';

const ChangePaymentMethodForm = ({
  clientSecret,
  stripe,
  customerId,
  subscriptionId,
  setNewPaymentMethodId,
  handleUpdateBilling,
  confirmClicked,
  setConfirmClicked,
}) => {
  const options = {
    clientSecret: clientSecret,
  };

  return (
    <Elements
      stripe={stripe}
      options={options}>
      <CardSetupForm
        clientSecret={clientSecret}
        customerId={customerId}
        subscriptionId={subscriptionId}
        setNewPaymentMethodId={setNewPaymentMethodId}
        handleUpdateBilling={handleUpdateBilling}
        confirmClicked={confirmClicked}
        setConfirmClicked={setConfirmClicked}
      />
    </Elements>
  );
};

export default ChangePaymentMethodForm;
