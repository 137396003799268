import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useNavigate,
} from "react-router-dom";
import PropTypes from "prop-types";
import LoginForm from "./views/Login";
import ResetPassword from "./components/ResetPassword"; // <-- Import the ResetPassword component
// import UserList from './components/userList';
import AdminPage from "./views/Admin";
import Billing from "./views/Billing";
import SnackbarMessage, {
  SnackBarMessage,
} from "./components/ui/SnackbarMessage";
import NavigationBar from "./components/NavigationBar";
import { useAuth } from "./context/AuthContext";
import UserList from "./views/Users";
import UserDetails from "./views/UserDetails";
import FirstTimeLoginForm from "./views/FirstTimeLogin";
import { CircularProgress } from "@mui/material";

function ProtectedRoute({ children, adminOnly = false }) {
  const { token, isLoading, role, triggeredLogout } = useAuth();
  const navigate = useNavigate();

  if (isLoading || triggeredLogout)
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}>
        {" "}
        <CircularProgress />
      </div>
    );

  if (!token) {
    navigate("/");
    return null;
  }
  if (adminOnly && role !== "admin") {
    navigate("/");
    return null;
  }

  return (
    <>
      <NavigationBar />
      {children}
    </>
  );
}

ProtectedRoute.propTypes = {
  children: PropTypes.node.isRequired,
  adminOnly: PropTypes.bool,
};

ProtectedRoute.defaultProps = {
  adminOnly: false,
};

export default function AppRouter({ stripe }) {
  const [message, setMessage] = useState("");
  const [isError, setIsError] = useState(false);
  const [snackBarOpen, setSnackBarOpen] = useState(false);
  const {
    isActive,
    firstTimeSetupComplete,
    users,
    authenticateUser,
    triggeredLogout,
    isLoading,
  } = useAuth();

  useEffect(() => {
    if (triggeredLogout) {
      window.location.reload();
    }
  }, [triggeredLogout]);
  if (isLoading || triggeredLogout)
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}>
        <CircularProgress />
      </div>
    );
  return (
    <>
      {" "}
      {snackBarOpen && (
        <SnackbarMessage
          setSnackBarOpen={setSnackBarOpen}
          message={message}
          snackBarOpen={snackBarOpen}
          isError={isError}
        />
      )}{" "}
      <Router>
        {" "}
        <Routes>
          <Route
            path="/"
            element={
              isActive && !firstTimeSetupComplete ? (
                <FirstTimeLoginForm
                  users={users}
                  authenticateUser={authenticateUser}
                  setIsError={setIsError}
                  setMessage={setMessage}
                  setSnackBarOpen={setSnackBarOpen}
                />
              ) : (
                <LoginForm
                  setIsError={setIsError}
                  setMessage={setMessage}
                  setSnackBarOpen={setSnackBarOpen}
                />
              )
            }
          />
          <Route
            path="/reset"
            element={
              <ResetPassword
                setIsError={setIsError}
                setMessage={setMessage}
                setSnackBarOpen={setSnackBarOpen}
              />
            }
          />
          <Route
            path="/users"
            element={
              <ProtectedRoute>
                <UserList
                  setIsError={setIsError}
                  setMessage={setMessage}
                  setSnackBarOpen={setSnackBarOpen}
                />
              </ProtectedRoute>
            }
          />
          <Route
            path="/admin"
            element={
              <ProtectedRoute>
                <AdminPage
                  setIsError={setIsError}
                  setMessage={setMessage}
                  setSnackBarOpen={setSnackBarOpen}
                  snackBarOpen={snackBarOpen}
                  message={message}
                  isError={isError}
                />
              </ProtectedRoute>
            }
          />
          <Route
            path="/user-details/:email"
            element={
              <ProtectedRoute>
                <UserDetails
                  setIsError={setIsError}
                  setMessage={setMessage}
                  setSnackBarOpen={setSnackBarOpen}
                  snackBarOpen={snackBarOpen}
                  message={message}
                  isError={isError}
                />
              </ProtectedRoute>
            }
          />
          <Route
            path="/billing"
            element={
              <ProtectedRoute>
                <Billing
                  stripe={stripe}
                  setIsError={setIsError}
                  setMessage={setMessage}
                  setSnackBarOpen={setSnackBarOpen}
                  snackBarOpen={snackBarOpen}
                  message={message}
                  isError={isError}
                />
              </ProtectedRoute>
            }
          />
        </Routes>
      </Router>
    </>
  );
}
