import React, { useState, useEffect, useContext, useCallback } from "react";
import {
  Card,
  CardContent,
  Button,
  Typography,
  Grid,
  Container,
  Skeleton,
  CircularProgress,
  Tooltip,
} from "@mui/material";

import { useNavigate } from "react-router-dom";
import { useAuth } from "../context/AuthContext";
import { makeStyles } from "@mui/styles";

import { SocketContext } from "../context/socket";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: "0",
    marginLeft: "%",
    width: "80%",
    [theme.breakpoints.up("md")]: {
      marginLeft: "auto !important",
      width: "100% !important",
      paddingLeft: "14% !important",
    },
  },
  card: {
    marginBottom: "20px",
    padding: "10px",
  },
  actionButton: {
    width: "100%",
  },
  activeUserCard: {
    backgroundColor: "#e8f5e9",
    position: "relative",
  },
  inactiveUserCard: {
    backgroundColor: "#ffebee",
    position: "relative",
  },
}));

function SkeletonLoader() {
  const classes = useStyles();
  return (
    <Container className={classes.container}>
      <Grid
        container
        spacing={3}>
        <Grid
          item
          xs={12}
          sm={6}>
          <Card style={{ marginBottom: "20px", padding: "10px" }}>
            <Skeleton
              variant="text"
              width="80%"
              height={15}
            />
          </Card>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}>
          <Card style={{ marginBottom: "20px", padding: "10px" }}>
            <Skeleton
              variant="text"
              width="80%"
              height={15}
            />
          </Card>
        </Grid>
      </Grid>

      <Grid
        container
        spacing={3}>
        {Array(3)
          .fill(0)
          .map((_, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              key={`skeleton-loader-${index}`}>
              <Card
                elevation={3}
                style={{ height: "100%" }}>
                <CardContent
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                  }}>
                  <Skeleton
                    variant="text"
                    width="60%"
                    height={24}
                  />
                  <div
                    style={{ marginTop: "5px", display: "flex", gap: "10px" }}>
                    <Skeleton
                      variant="rectangular"
                      width="70px"
                      height="30px"
                    />
                    <Skeleton
                      variant="rectangular"
                      width="70px"
                      height="30px"
                    />
                  </div>
                </CardContent>
              </Card>
            </Grid>
          ))}
      </Grid>
    </Container>
  );
}

function UserList({ setIsError, setMessage, setSnackBarOpen }) {
  const classes = useStyles();

  const [users, setUsers] = useState([]);
  const [apiError, setError] = useState(null);
  const [totalLicenses, setTotalLicenses] = useState(0);
  const [permanentUsers, setPermanentUsers] = useState(0);
  const [activeUsers, setActiveUsers] = useState(0);
  const [isFetching, setIsFetching] = useState(true);
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [loadingAction, setLoadingAction] = useState(null);
  const socket = useContext(SocketContext);
  const navigate = useNavigate();
  const { isAdmin, id, axiosInstance } = useAuth();

  const determineStatus = (role, password) => {
    if (role === "" && password === "NotSet") return "Not Invited";
    if (role !== "" && password === "NotSet") return "Invited";
    if (role !== "" && password !== "NotSet") return "Created";
  };
  const fetchUsers = async () => {
    try {
      const { data } = await axiosInstance.post("/users", null);

      let fullList = Object.keys(data).map((email) => ({
        isPermanent: data[email].IsPermanent,
      }));

      let userList = Object.keys(data)
        .filter((email) => !data[email].IsHidden)
        .map((email) => ({
          id: email,
          email,
          name: data[email].Name,
          role: data[email].Role === "NotSet" ? "" : data[email].Role,
          password: data[email].Password,
          isActive: data[email].IsActive,
          isHidden: data[email].IsHidden,
          isPermanent: data[email].IsPermanent,
        }));

      userList = userList.map((user) => ({
        ...user,
        status: determineStatus(user.role, user.password),
      }));
      const permanentUsersCount = fullList.filter(
        (user) => user.isPermanent
      ).length;
      const activeUsersCount = userList.filter((user) => user.isActive).length;

      setPermanentUsers(permanentUsersCount);
      setActiveUsers(activeUsersCount);
      setUsers(userList);
      await fetchLicenses();
    } catch (error) {
      setIsInitialLoad(false);
    }
  };

  const fetchLicenses = async () => {
    try {
      const { data } = await axiosInstance.post("/licenses", null);
      setTotalLicenses(data.licenses);
      setIsFetching(false);
      setIsInitialLoad(false);
    } catch (error) {
      setError(error.message);
      setMessage(error.message);
      setIsError(true);
      setSnackBarOpen(true);
      setIsFetching(false);
    }
  };

  const handleUserAction = async (action, email, user = null) => {
    const url = `/user/${email}/${action}`;
    const actionKey = `${email}@${action}`;
    setLoadingAction(actionKey);

    try {
      const { data } = await axiosInstance.post(url, {
        user: user,
      });
      let userData = data.user;

      if (action === "logout") {
        removeOfflineUser(userData.Email);
        socket.emit("logout", { userId: userData.Email, initiatorId: id });
      } else if (action === "login") {
        addOnlineUser(userData.Email);
        socket.emit("go-online", { userId: userData.Email, initiatorId: id });
      } else if (action === "invite") {
        setUsers((prevUsers) => {
          const updatedUsers = prevUsers.map((existingUser) =>
            existingUser.email === userData.Email
              ? {
                  ...existingUser,
                  role: userData.Role,
                  status: determineStatus(
                    userData.Role,
                    existingUser.Password ? existingUser.Password : ""
                  ),
                }
              : existingUser
          );

          return updatedUsers;
        });
        socket.emit("invite-user", { userData: userData, initiatorId: id });
      }

      setMessage(`${action} completed successfully!`);
      setIsError(false);
    } catch (error) {
      setMessage(error.message);
      setIsError(true);
    } finally {
      setLoadingAction(null);
      setSnackBarOpen(true);
    }
  };

  const addOnlineUser = useCallback((id) => {
    setUsers((prev) =>
      prev.map((user) => {
        if (user.id === id) {
          const userCopy = { ...user, isActive: true };
          return userCopy;
        } else {
          return user;
        }
      })
    );
  }, []);

  const removeOfflineUser = useCallback((id) => {
    setUsers((prev) =>
      prev.map((user) => {
        if (user.id === id) {
          const userCopy = { ...user, isActive: false };
          return userCopy;
        } else {
          return user;
        }
      })
    );
  }, []);

  const updateInvitedUser = useCallback((userData) => {
    setUsers((prevUsers) => {
      const updatedUsers = prevUsers.map((existingUser) =>
        existingUser.email === userData.Email
          ? {
              ...existingUser,
              role: userData.Role,
              status: determineStatus(
                userData.Role,
                existingUser.Password ? existingUser.Password : ""
              ),
            }
          : existingUser
      );

      return updatedUsers;
    });
  }, []);
  const handleAddOnlineUser = useCallback(
    (data) => {
      if (data.initiatorId !== id) {
        addOnlineUser(data.userId);
      }
    },
    [id, addOnlineUser]
  );

  const handleInvitedUser = useCallback(
    (data) => {
      if (data.initiatorId !== id) {
        updateInvitedUser(data.userData);
      }
    },
    [id, updateInvitedUser]
  );

  const handleRemoveOfflineUser = useCallback(
    (data) => {
      if (data.initiatorId !== id) {
        removeOfflineUser(data.userId);
      }
    },
    [id, removeOfflineUser]
  );

  useEffect(() => {
    // Socket init
    socket.on("add-online-user", handleAddOnlineUser);
    socket.on("remove-offline-user", handleRemoveOfflineUser);
    socket.on("invite-new-user", handleInvitedUser);
    return () => {
      // Clean up event listeners when the component is unmounted
      socket.off("add-online-user", handleAddOnlineUser);
      socket.off("remove-offline-user", handleRemoveOfflineUser);
      socket.off("invite-new-user", handleInvitedUser);
    };
  }, [handleAddOnlineUser, handleRemoveOfflineUser, socket]);

  useEffect(() => {
    if (users.length > 0) {
      setLoadingAction(null);
    }
  }, [users]);

  if (apiError) {
    return <div>Error: {apiError}</div>;
  }

  // Compute the total number of active users
  const activeUsersCount = users.filter((u) => u.IsActive).length;
  // Check if all licenses are occupied
  const allLicensesOccupied =
    activeUsersCount === totalLicenses - permanentUsers;

  useEffect(() => {
    fetchUsers();
  }, []);

  return (
    <div style={{ paddingTop: "6.4rem" }}>
      <Container className={classes.container}>
        <h2> </h2>
      </Container>
      {isFetching && isInitialLoad ? (
        <SkeletonLoader />
      ) : (
        <Container className={classes.container}>
          <Grid
            container
            spacing={3}>
            <Grid
              item
              xs={12}
              sm={6}>
              <Card style={{ marginBottom: "20px", padding: "10px" }}>
                <Typography variant="subtitle1">
                  <span>Seats Available: </span>
                  {totalLicenses - permanentUsers - activeUsers > 0 ? (
                    totalLicenses - permanentUsers - activeUsers
                  ) : (
                    <strong>None</strong>
                  )}
                </Typography>
              </Card>
            </Grid>

            {isAdmin() && (
              <Grid
                item
                xs={12}
                sm={6}>
                <Card style={{ marginBottom: "20px", padding: "10px" }}>
                  <Typography variant="subtitle1">
                    Permanent Users: {permanentUsers}
                  </Typography>
                </Card>
              </Grid>
            )}
          </Grid>

          <Grid
            container
            spacing={3}>
            {users.map((user) => (
              <Grid
                item
                xs={12}
                sm={6}
                md={4}
                key={user.email}>
                <Card
                  elevation={3}
                  className={
                    user.isActive
                      ? classes.activeUserCard
                      : classes.inactiveUserCard
                  }>
                  <CardContent>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        marginBottom: "10px",
                      }}>
                      <div>
                        <Typography
                          variant="h6"
                          component="div">
                          {user.name}
                        </Typography>
                      </div>
                      {isAdmin() && (
                        <div>
                          {determineStatus(user.role, user.password) ===
                          "Not Invited" ? (
                            <Button
                              variant="contained"
                              color="primary"
                              size="small"
                              disabled={loadingAction}
                              onClick={() => {
                                handleUserAction("invite", user.email, {
                                  email: user.email,
                                  role: "User",
                                });
                              }}>
                              Invite
                            </Button>
                          ) : (
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                height: "100%",
                              }}>
                              {determineStatus(user.role, user.password) ===
                              "Created" ? (
                                <></>
                              ) : (
                                "Invited"
                              )}
                            </div>
                          )}{" "}
                        </div>
                      )}
                    </div>
                    <div style={{ display: "flex", justifyContent: "normal" }}>
                      {user.isActive ? (
                        <Tooltip title="Click to logout this user">
                          <Button
                            variant="outlined"
                            color="primary"
                            disabled={isFetching}
                            onClick={() =>
                              handleUserAction("logout", user.email, {
                                email: user.email,
                                role: "User",
                              })
                            }>
                            {loadingAction === `${user.email}@logout` ? (
                              <CircularProgress
                                size={24}
                                color="inherit"
                              />
                            ) : (
                              "Logout"
                            )}
                          </Button>
                        </Tooltip>
                      ) : (
                        <Tooltip
                          title={
                            allLicensesOccupied
                              ? "All seats full, logout a user to log this user in"
                              : "Click to login this user"
                          }>
                          <span>
                            <Button
                              variant="outlined"
                              color="primary"
                              onClick={() =>
                                handleUserAction("login", user.email, {
                                  email: user.email,
                                  role: "User",
                                })
                              }
                              disabled={
                                allLicensesOccupied ||
                                loadingAction === `${user.email}@login`
                              }>
                              {loadingAction === `${user.email}@login` ? (
                                <CircularProgress
                                  size={24}
                                  color="inherit"
                                />
                              ) : (
                                "Login"
                              )}
                            </Button>
                          </span>
                        </Tooltip>
                      )}
                    </div>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Container>
      )}
    </div>
  );
}
export default UserList;
