import React, { useState, useRef, useEffect } from 'react';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import CardSection from './CardSection';

export default function CardSetupForm({
  clientSecret,
  confirmClicked,
  setNewPaymentMethodId,
  setConfirmClicked,
}) {
  const stripe = useStripe();
  const elements = useElements();

  const [errorMessage, setErrorMessage] = useState(null);
  const [isCardComplete, setIsCardComplete] = useState(false);

  const buttonRef = useRef(null);

  const handleCardChange = (event) => {
    setIsCardComplete(event.complete);
    setErrorMessage(event.error ? event.error.message : null);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    const cardElement = elements.getElement(CardElement);

    try {
      const { setupIntent, error } = await stripe.confirmCardSetup(
        clientSecret,
        {
          payment_method: {
            card: cardElement,
          },
        }
      );

      if (error) {
        console.error(error);
        setErrorMessage(
          error.message || 'An error occurred during card setup.'
        );
      } else {
        const newPaymentMethodId = setupIntent.payment_method;
        setNewPaymentMethodId(newPaymentMethodId);
      }
    } catch (error) {
      console.error(error);
      setErrorMessage('An unexpected error occurred.');
    }
  };

  useEffect(() => {
    if (confirmClicked && buttonRef.current && isCardComplete) {
      setErrorMessage(null);
      buttonRef.current.click();
    } else {
      setErrorMessage('Please provide a valid card.');
      setConfirmClicked(false);
    }
  }, [confirmClicked]);

  return (
    <div style={{ padding: '1.6rem 0.4rem' }}>
      <form onSubmit={handleSubmit}>
        {/* Pass the handleCardChange callback to the child component */}
        <CardSection handleCardChange={handleCardChange} />

        {errorMessage && (
          <div style={{ color: 'red', marginTop: '10px' }}>{errorMessage}</div>
        )}

        {/* Use the buttonRef to focus and submit the form */}
        <button
          ref={buttonRef}
          type="submit"
          disabled={!stripe || !isCardComplete}
          style={{ visibility: 'hidden', position: 'absolute' }}>
          Save Card
        </button>
      </form>
    </div>
  );
}
