import React from "react";
import Card from "@mui/material/Card";
import { DataGrid } from "@mui/x-data-grid";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import { Box, Button, Switch, TextField } from "@mui/material";
import RoleRow from "../Users/RoleRow";
import { useNavigate } from "react-router-dom";

const UserManagement = ({
  filteredUsers,
  rowSelectionModel,
  setRowSelectionModel,
  sortModel,
  setSortModel,
  searchTerm,
  setSearchTerm,
  showHiddenUsers,
  setShowHiddenUsers,
  isLoading,
  isBulkEditing,
  setBulkUserEdits,
  setBulkUserInvites,
  handleBulkEditPost,
  handleBulkInvitePost,
  isBulkInviting,
  setIsBulkEditing,
  setIsBulkInviting,
  users,
  filterModel,
  setFilterModel,
  bulkUserInvites,
  bulkUserEdits,
  determineStatus,
  handleRoleChange,
  setIsConfirmingBulkInvite,
  handleAction,
  setIsHidingUsers,
  isHidingUsers,
}) => {
  const handleCheckboxChange = (selectedRows) => {
    if (!isBulkEditing && !isBulkInviting) {
      setRowSelectionModel([]);
      return;
    }

    setRowSelectionModel(selectedRows.selectionModel || selectedRows);

    const selectedUsers = isBulkEditing ? bulkUserEdits : bulkUserInvites;

    const updatedSelectedUsers = selectedUsers.filter((u) =>
      selectedRows.some((row) => row === u.email)
    );

    selectedRows.forEach((row) => {
      const existingIndex = updatedSelectedUsers.findIndex(
        (u) => u.email === row
      );

      if (existingIndex === -1) {
        const userToAdd = filteredUsers.find((user) => user.email === row);

        if (userToAdd) {
          updatedSelectedUsers.push({
            email: userToAdd.email,
            role: userToAdd.role,
          });
        }
      }
    });

    if (isBulkEditing) {
      setBulkUserEdits([...updatedSelectedUsers]);
    } else if (isBulkInviting) {
      setBulkUserInvites([...updatedSelectedUsers]);
    }
  };
  const navigate = useNavigate();

  const columns = [
    { field: "email", headerName: "Email", flex: 3 },
    { field: "name", headerName: "Name", flex: 2 },
    {
      field: "role",
      headerName: "Role",
      flex: 2,
      renderCell: (params) => {
        if (
          isBulkEditing ||
          (isBulkInviting && params.row.status === "Not Invited")
        ) {
          return (
            <RoleRow
              row={params.row}
              onRoleChange={handleRoleChange}
              disabled={
                isBulkEditing
                  ? !bulkUserEdits.some((u) => u.email === params.row.email)
                  : !bulkUserInvites.some((u) => u.email === params.row.email)
              }
            />
          );
        } else {
          return <span>{params.value}</span>;
        }
      },
    },
    ...(showHiddenUsers
      ? [
          {
            field: "isHidden",
            headerName: "Visible",
            flex: 2,
            renderCell: (params) =>
              params.value ? (
                <CancelIcon color="error" />
              ) : (
                <CheckCircleIcon color="primary" />
              ),
          },
        ]
      : []),
    {
      field: "status",
      headerName: "Status",
      flex: 2,
      sortable: true,
      renderCell: (params) => {
        const status = determineStatus(params.row.role, params.row.password);
        if (status === "Not Invited") {
          return (
            <Button
              variant="contained"
              color="primary"
              size="small"
              disabled={isBulkEditing || isBulkInviting}
              onClick={() => {
                handleAction("invite", params.row, params.row.id);
              }}>
              Invite
            </Button>
          );
        } else if (status === "Registered") {
          return (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "100%",
              }}>
              <CheckCircleIcon color="primary" />
            </div>
          );
        }
        return <span>{status}</span>;
      },
    },
    {
      field: "details",
      headerName: "",
      sortable: false,
      flex: 2,
      renderCell: (params) => (
        <Button
          variant="contained"
          disabled={isBulkEditing || isBulkInviting}
          onClick={() => navigate(`/user-details/${params.row.id}`)}>
          Edit
        </Button>
      ),
    },
  ];

  return (
    <Card style={{ padding: "10px", marginTop: "4px" }}>
      <div
        style={{
          marginBottom: "10px",
          padding: "10px",
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          justifyContent: "space-between",
        }}>
        <TextField
          type="text"
          sx={{
            fontSize: "20px",
            "& input": {
              padding: "6px 12px",
            },
            width: "100%",
            marginBottom: "10px",
          }}
          placeholder="Search..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          disabled={isBulkEditing && isBulkInviting}
        />
        <div
          style={{
            fontSize: "16px",
            marginLeft: "auto",
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "flex-end",
              gap: "0.6rem",
              flexDirection: { xs: "column", md: "row", lg: "row" },
            }}>
            {/* Bulk Edit Button */}
            {!isBulkInviting && (
              <Box
                sx={{ display: "flex", alignItems: "flex-end", gap: "0.6rem" }}>
                <Button
                  variant="contained"
                  disabled={isLoading}
                  onClick={() => {
                    setIsBulkEditing((prev) => !prev);
                    if (isBulkEditing === true) {
                      setBulkUserEdits([]);
                    }
                  }}>
                  {isBulkEditing ? "Cancel" : "Bulk Edit"}
                </Button>
                {isBulkEditing && (
                  <Button
                    variant="contained"
                    disabled={bulkUserEdits.length === 0 || isLoading}
                    onClick={async () => await handleBulkEditPost()}>
                    Save Bulk Edits
                  </Button>
                )}
              </Box>
            )}

            {/* Bulk Invite Button */}
            {!isBulkEditing && (
              <Box
                sx={{ display: "flex", alignItems: "flex-end", gap: "0.6rem" }}>
                <Button
                  variant="contained"
                  disabled={isLoading}
                  onClick={() => {
                    setIsBulkInviting((prev) => !prev);
                    if (isBulkInviting) {
                      setBulkUserInvites([]);
                    }
                  }}>
                  {isBulkInviting ? "Cancel" : "Bulk Invite"}
                </Button>
                {isBulkInviting && (
                  <Button
                    variant="contained"
                    disabled={
                      bulkUserInvites.length === 0 ||
                      isLoading ||
                      !bulkUserInvites.every(
                        (user) => user.role && user.role.length > 0
                      )
                    }
                    onClick={setIsConfirmingBulkInvite}>
                    Send Bulk Invites
                  </Button>
                )}
              </Box>
            )}
          </Box>
          {/* Toggle Switches */}
          <div
            style={{
              fontSize: "16px",
              marginLeft: "auto",
              display: "flex",
              flexDirection: "column",
              gap: "0.6rem",
            }}>
            {/* Toggle for Hide Users */}
            <div
              style={{ display: "flex", alignItems: "center", gap: "0.6rem" }}>
              <Switch
                checked={isHidingUsers}
                onChange={() => setIsHidingUsers((prev) => !prev)}
                disabled={isLoading}
              />
              Hide Users
            </div>

            {/* Toggle for Show Hidden Users */}
            <div
              style={{ display: "flex", alignItems: "center", gap: "0.6rem" }}>
              <Switch
                checked={showHiddenUsers}
                onChange={() => setShowHiddenUsers(!showHiddenUsers)}
                disabled={isLoading}
              />
              Show Hidden Users
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          flexGrow: 1,
          width: "100%",
          overflow: "hidden",
          height: "81vh",
          marginTop: "10px",
        }}>
        <DataGrid
          rows={filteredUsers}
          columns={columns.map((column) => {
            if (column.field === "role") {
              return {
                ...column,
                editable: isBulkEditing,
              };
            }

            if (column.field === "name" || column.field === "email") {
              return {
                ...column,

                renderCell: (params) => (
                  <div
                    style={{
                      color:
                        !isBulkEditing && !isBulkInviting
                          ? "#000"
                          : rowSelectionModel.find(
                              (row) => row === params.row.email
                            )
                          ? "#000"
                          : "#888888",
                    }}>
                    {params.value}
                  </div>
                ),
              };
            }

            return column;
          })}
          pageSize={5}
          rowsPerPageOptions={[5]}
          onRowSelectionModelChange={(newSelection) => {
            handleCheckboxChange(newSelection);
          }}
          loading={isLoading}
          rowSelectionModel={rowSelectionModel}
          checkboxSelection={isBulkEditing || isBulkInviting}
          getRowId={(row) => row.email}
          sortModel={sortModel}
          localeText={{
            noRowsLabel: users.length === 0 ? "Loading..." : "No rows",
          }}
          onSortModelChange={(params) => {
            setSortModel(params.sortModel);
          }}
          filterMode="client"
          disableMultipleColumnsFiltering
          filterModel={filterModel}
          onFilterModelChange={(params) => {
            setFilterModel(params.filterModel);
          }}
          disableRowSelectionOnClick
        />
      </div>
    </Card>
  );
};

export default UserManagement;
