import React from "react";
import { loadStripe } from "@stripe/stripe-js";
import { AuthProvider } from "./context/AuthContext";
import { SocketContext, socket } from "./context/socket";
import AppRouter from "./AppRouter";
import { ThemeProvider } from "@mui/material/styles";
import designSystem from "./theme";
export default function App() {
  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

  return (
    <SocketContext.Provider value={socket}>
      <AuthProvider>
        <ThemeProvider theme={designSystem}>
          <AppRouter stripe={stripePromise} />
        </ThemeProvider>
      </AuthProvider>
    </SocketContext.Provider>
  );
}
