import React, {
  createContext,
  useState,
  useContext,
  useEffect,
  useMemo,
} from "react";
import PropTypes from "prop-types";
import axios from "axios";

const AuthContext = createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [token, setToken] = useState(null);
  const [role, setRole] = useState(null);
  const [id, setId] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isUserReady, setIsUserReady] = useState(false);
  const [isActive, setIsActive] = useState(null);
  const [firstTimeSetupComplete, setFirstTimeSetupComplete] = useState(null);
  const [users, setUsers] = useState(null);
  const [triggeredLogout, setTriggeredLogout] = useState(null);
  const currentLocation = window.location;

  const axiosInstance = axios.create({
    baseURL:
      currentLocation.origin === "http://localhost:3000"
        ? "http://localhost:5000/api"
        : currentLocation.origin + "/api",
  });

  axiosInstance.interceptors.request.use(
    async (config) => {
      const localToken = localStorage.getItem("seat-swapper-token");
      const authHeader = localToken ? `${localToken}` : "";

      config.headers = {
        ...config.headers,
        Authorization: authHeader,
        "Content-Type": "application/json",
      };
      config.mode = "cors";

      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  const login = (newToken, userRole, id) => {
    setToken(newToken);
    setRole(userRole);
    setId(id);
  };

  const logout = () => {
    localStorage.removeItem("seat-swapper-token");
    setToken(null);
    setRole(null);
    setId(null);
    setTriggeredLogout(true);
  };

  const authenticateUser = async (email = null, password, id = null) => {
    try {
      const response = id
        ? await axiosInstance.post("/first-time-setup", {
            id,
            password,
          })
        : await axiosInstance.post("/login", {
            username: email,
            password,
          });

      if (response.status === 200) {
        const data = response.data;

        if (data.success) {
          localStorage.setItem("seat-swapper-token", data.token);
          login(data.token, data.role);
          return true;
        }
      }
      return false;
    } catch (error) {
      if (error.message === "Network Error") {
        throw new Error("Cannot reach backend server.");
      } else {
        throw error;
      }
    }
  };

  const fetchUser = async () => {
    try {
      const { data } = await axiosInstance.get("/user");
      login(data.token, data.role, data.id);
    } catch (error) {
      // console.error(error);

      try {
        const setupResponse = await axiosInstance.get("/first-time-setup");

        if (setupResponse.status === 200) {
          const setupData = setupResponse.data;
          setIsActive(setupData.isActive);
          setFirstTimeSetupComplete(setupData.firstTimeSetupComplete);

          setUsers(
            Object.keys(setupData.users).map((email) => ({
              id: setupData.users[email].Id,
              email,
            }))
          );
        }
      } catch (setupError) {
        console.error("Error during first-time setup:", setupError);
      }
    } finally {
      setIsLoading(false);
      setIsUserReady(true);
    }
  };

  const isAdmin = () => role === "Admin";

  const contextValue = useMemo(
    () => ({
      token,
      role,
      id,
      login,
      logout,
      authenticateUser,
      isActive,
      firstTimeSetupComplete,
      axiosInstance,
      isLoading,
      isAdmin,
      users,
      triggeredLogout,
    }),
    [
      token,
      role,
      id,
      isLoading,
      isActive,
      firstTimeSetupComplete,
      users,
      triggeredLogout,
    ]
  );

  useEffect(() => {
    if (axiosInstance && !isUserReady) {
      fetchUser();
    }
  }, [axiosInstance, isUserReady]);

  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
}

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AuthProvider;
