import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  TextField,
  Card,
  CardContent,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress,
} from "@mui/material";
import { useAuth } from "../context/AuthContext";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    marginTop: "5%",
  },
  card: {
    width: "500px",
  },
  buttons: {
    marginRight: theme.spacing(1),
  },
  loader: {
    marginLeft: theme.spacing(1),
  },
}));

function UserDetails({
  setIsError,
  setMessage,
  setSnackBarOpen,
  snackBarOpen,
  message,
  isError,
}) {
  const classes = useStyles();
  const [userData, setUserData] = useState(null);
  const [hasChanged, setHasChanged] = useState(false);
  const [originalData, setOriginalData] = useState(null);
  const [loadingBack, setLoadingBack] = useState(false);
  const [loadingSave, setLoadingSave] = useState(false);
  const { token, axiosInstance } = useAuth();
  const { email } = useParams();
  const navigate = useNavigate();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    const updatedData = { ...userData, [name]: value };
    setUserData(updatedData);
    setHasChanged(JSON.stringify(updatedData) !== JSON.stringify(originalData));
  };

  const handleSaveOrBackClick = async (type) => {
    if (type === "back") {
      setLoadingBack(true);
      try {
        await new Promise((resolve) => setTimeout(resolve, 1000));
        setLoadingBack(false);
        navigate("/admin");
      } catch (error) {
        setLoadingBack(false);
        setMessage("Error navigating back:", error);
        setIsError(true);
        setSnackBarOpen(true);
      }
    } else if (type === "save") {
      setLoadingSave(true);
      try {
        const { data } = await axiosInstance.post(`/user/${email}/edit`, {
          user: {
            Role: userData.Role,
            IsHidden: userData.IsHidden,
            IsPermanent: userData.IsPermanent,
          },
        });
        if (data[1] !== 500) {
          setLoadingSave(false);
          setMessage("User details saved successfully!");
          setIsError(false);
          setSnackBarOpen(true);
          navigate("/admin", {
            state: { successMessage: "User details saved successfully!" },
          });
        } else {
          throw new Error();
        }
      } catch (error) {
        setLoadingSave(false);

        setMessage("Error saving user details:", error);
        setIsError(true);
        setSnackBarOpen(true);
      }
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await axiosInstance.post(`/users/${email}`, null);
        setUserData(data);
        setOriginalData(data);
      } catch (error) {
        setMessage("Error fetching user details:", error);
        setIsError(true);
        setSnackBarOpen(true);
      }
    };

    fetchData();
  }, [email, token]);
  if (!userData) return <div>Loading...</div>;

  return (
    <div className={classes.root}>
      <Card className={classes.card}>
        <CardContent>
          <h1>
            {userData.Name}
            &apos;s Details
          </h1>
          <TextField
            label="Email"
            value={userData.Email}
            name="Email"
            fullWidth
            margin="normal"
            disabled
          />
          <FormControl
            fullWidth
            margin="normal">
            <InputLabel id="role-label">Role</InputLabel>
            <Select
              label="Role"
              labelId="role-label"
              value={userData.Role}
              name="Role"
              onChange={handleInputChange}>
              <MenuItem value="Admin">Admin</MenuItem>
              <MenuItem value="User">User</MenuItem>
              <MenuItem value="Disabled">Disabled</MenuItem>
              <MenuItem value="NotSet">NotSet</MenuItem>
            </Select>
          </FormControl>
          <h3> </h3>
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleSaveOrBackClick("back")}
            className={classes.buttons}
            disabled={loadingBack}>
            Back
            {loadingBack && (
              <CircularProgress
                size={20}
                className={classes.loader}
              />
            )}
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleSaveOrBackClick("save")}
            className={classes.buttons}
            disabled={!hasChanged || loadingSave}>
            Save
            {loadingSave && (
              <CircularProgress
                size={20}
                className={classes.loader}
              />
            )}
          </Button>
          {/* <Button
            variant="contained"
            color="primary"
            onClick={handleInvite}
          >
            Invite User
          </Button> */}
        </CardContent>
      </Card>
    </div>
  );
}

export default UserDetails;
