import React from "react";
import { Snackbar } from "@mui/base";
import { makeStyles } from "@mui/styles";
import { GridCloseIcon } from "@mui/x-data-grid";
import CheckCircle from "@mui/icons-material/CheckCircle";
import { Alert } from "@mui/material";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  snackbar: {
    fontWeight: "bold",
    bottom: "2%",
    left: "50%",
    transform: "translateX(-50%)",
    position: "fixed",
    zIndex: 9999,
    textAlign: "center",
  },
  icon: {
    color: "white",
  },
  success: {
    backgroundColor: "green",
    padding: theme.spacing(1), // Add padding
  },
  error: {
    backgroundColor: "red",
    padding: theme.spacing(1), // Add padding
  },
}));

const SnackbarMessage = ({
  setSnackBarOpen,
  message,
  snackBarOpen,
  isError,
}) => {
  const classes = useStyles();

  const icon = isError ? <GridCloseIcon color="secondary" /> : <CheckCircle />;
  const snackbarClasses = clsx(
    classes.snackbar,
    isError ? classes.error : classes.success
  );

  return (
    <Snackbar
      open={snackBarOpen}
      autoHideDuration={6000}
      onClose={() => setSnackBarOpen(false)}
      sx={{ width: "100%", maxWidth: "600px", color: "#fff", display: "flex" }}>
      <Alert
        onClose={() => setSnackBarOpen(false)}
        severity={isError ? "error" : "success"}
        sx={{
          width: "100%",
          maxWidth: "360px",
          padding: "2px",
          color: "#fff",
          textAlign: "center",
        }}
        className={snackbarClasses}>
        {message || "An error occurred. Please try again."}
      </Alert>
    </Snackbar>
  );
};

export default SnackbarMessage;
