import React, { useState } from "react";
import { Grid, TextField, Paper, Button, Typography } from "@mui/material";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useAuth } from "../context/AuthContext";
import "../styles/userList.css";

export default function ResetPassword({
  setIsError,
  setMessage,
  setSnackBarOpen,
}) {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");
  const { axiosInstance } = useAuth();
  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!token) {
      setError("Invalid or missing token.");
      return;
    }

    if (!password || password.length < 8) {
      setError("Password should be at least 8 characters long.");
      return;
    }

    if (password !== confirmPassword) {
      setError("Passwords do not match.");
      return;
    }

    setIsLoading(true);

    try {
      const { data } = await axiosInstance.post("/password_verify", {
        password,
        token,
      });
      if (data.success) {
        setIsLoading(false);
        navigate("/");
        setIsError(false);
        setMessage("Password successfully changed!");
        setSnackBarOpen(true);
        return;
      }
      throw new Error("Invalid token or password!");
    } catch (err) {
      setIsLoading(false);
      setIsError(false);
      setMessage(err);
      setSnackBarOpen(true);
      setError(err.message);
    }
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}>
      <Paper
        elevation={3}
        style={{ padding: 30, maxWidth: 500 }}>
        <Typography
          variant="h5"
          style={{ textAlign: "center", marginBottom: 20 }}>
          Password Setup & Reset
        </Typography>
        <form onSubmit={handleSubmit}>
          <Grid
            container
            spacing={2}
            direction="row"
            justifyContent="center"
            alignItems="center">
            <Grid
              item
              xs={12}>
              <TextField
                fullWidth
                label="New Password"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </Grid>
            <Grid
              item
              xs={12}>
              <TextField
                fullWidth
                label="Confirm Password"
                type="password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
            </Grid>
            <Grid
              item
              xs={12}>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                size="large"
                disabled={isLoading}>
                {isLoading ? "Updating..." : "Update Password"}
              </Button>
            </Grid>
            {error && (
              <Grid
                item
                xs={12}
                style={{ display: "flex", justifyContent: "center" }}>
                <Typography color="error">{error}</Typography>
              </Grid>
            )}
          </Grid>
        </form>
      </Paper>
    </div>
  );
}
