import React, { useState, useEffect, useMemo } from "react";
import { DataGrid } from "@mui/x-data-grid";
import {
  Grid,
  Container,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Tabs,
  Tab,
} from "@mui/material";

import { useAuth } from "../context/AuthContext";
import { makeStyles } from "@mui/styles";

import UserManagement from "../components/Admin/UserManagement";
import LogViewer from "../components/Admin/LogViewer";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: "0",

    width: "100%",
    [theme.breakpoints.up("md")]: {
      marginLeft: "auto !important",
      width: "100% !important",
      paddingLeft: "14% !important",
    },
  },
  card: {
    marginBottom: "20px",
    padding: "10px",
  },
  actionButton: {
    width: "100%",
  },
  activeUserCard: {
    backgroundColor: "#e8f5e9",
    position: "relative",
  },
  inactiveUserCard: {
    backgroundColor: "#ffebee",
    position: "relative",
  },
  dataGrid: {
    border: "1px solid #e0e0e0",
    "& .MuiDataGrid-header": {
      backgroundColor: "#f5f5f5",
    },
    "& .MuiDataGrid-row": {
      "&:nth-of-type(odd)": {
        backgroundColor: "#f9f9f9",
      },
      "&.Mui-selected": {
        backgroundColor: "#ffc107",
      },
    },
  },

  customToolbar: {
    display: "flex",
    justifyContent: "space-between",
    padding: "10px",
    backgroundColor: "#f5f5f5",
    borderBottom: "1px solid #e0e0e0",
  },
  customPagination: {
    backgroundColor: "#f5f5f5",
  },
}));
function Admin({
  setIsError,
  setMessage,
  setSnackBarOpen,
  snackBarOpen,
  message,
  isError,
}) {
  const classes = useStyles();
  const [selectedTab, setSelectedTab] = useState(0);
  const [logs, setLogs] = useState([]);
  const [visibleUsers, setVisibleUsers] = useState([]);
  const [users, setUsers] = useState([]);
  const [showHiddenUsers, setShowHiddenUsers] = useState(false);
  const [isHidingUsers, setIsHidingUsers] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [rowSelectionModel, setRowSelectionModel] = useState([]);
  const [roleDialogOpen, setRoleDialogOpen] = useState(false);
  const [isBulkEditing, setIsBulkEditing] = useState(false);
  const [isBulkInviting, setIsBulkInviting] = useState(false);
  const { token, logout, axiosInstance } = useAuth();

  const [bulkUserEdits, setBulkUserEdits] = useState([]);
  const [bulkUserInvites, setBulkUserInvites] = useState([]);

  const [isConfirmingBulkInvite, setIsConfirmingBulkInvite] = useState(false);

  const [sortModel, setSortModel] = useState([
    {
      field: "name",
      sort: "asc",
    },
  ]);

  const [filterModel, setFilterModel] = useState({
    items: [
      {
        field: "isHidden",
        operator: "equals",
        value: "false",
      },
    ],
  });
  const [isLoading, setIsLoading] = useState(false);
  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const filteredUsers = useMemo(
    () =>
      users.filter((user) =>
        !isHidingUsers
          ? user.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
            user.name.toLowerCase().includes(searchTerm.toLowerCase())
          : (user.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
              user.name.toLowerCase().includes(searchTerm.toLowerCase())) &&
            user.role !== "User"
      ),
    [users, searchTerm, isHidingUsers]
  );

  const determineStatus = (role, password) => {
    if (role === "" && password === "NotSet") return "Not Invited";
    if (role !== "" && password === "NotSet") return "Invited";
    if (role !== "" && password !== "NotSet") return "Registered";

    return "unknown";
  };
  const fetchLogs = async () => {
    try {
      const { data } = await axiosInstance.get("/admin/log");

      const mappedLogs = data.log.map((log, index) => ({
        id: index + 1,
        action: log.action,
        time: new Date(log.timestamp * 1000).toLocaleString("en-US", {
          year: "numeric",
          month: "numeric",
          day: "numeric",
          hour: "numeric",
          minute: "numeric",
          second: "numeric",
          timeZoneName: "short",
        }),
        sourceUser: log.source_user,
        targetUser: log.target_user,
        status: log.status,
      }));

      setLogs(mappedLogs);
    } catch (error) {
      setMessage("Error fetching logs:", error);
      setIsError(true);
      setSnackBarOpen(true);
      setIsLoading(false);
    }
  };

  const fetchUsers = async () => {
    try {
      const { data } = await axiosInstance.post("/users", null);

      let userList = Object.keys(data).map((email) => ({
        id: email,
        email,
        name: data[email].Name,
        role: data[email].Role === "NotSet" ? "" : data[email].Role,
        password: data[email].Password,
        isActive: data[email].IsActive,
        isHidden: data[email].IsHidden,
        isPermanent: data[email].IsPermanent,
      }));
      userList = userList.map((user) => ({
        ...user,
        status: determineStatus(user.role, user.password),
      }));

      if (!showHiddenUsers) {
        userList = userList.filter((user) => !user.isHidden);
      }

      setUsers(userList);
      setIsLoading(false);
    } catch (error) {
      setMessage("Error fetching users:", error);
      setIsError(true);
      setSnackBarOpen(true);
      setIsLoading(false);
    }
  };
  const handleRoleChange = (id, newRole) => {
    const collection = isBulkEditing ? bulkUserEdits : bulkUserInvites;

    const updateCollection = (prev) => {
      const newArr = [...prev];
      const editIndex = newArr.findIndex((edit) => edit.email === id);

      if (editIndex !== -1) {
        newArr[editIndex].role = newRole;
      } else {
        const user = users.find((user) => user.id === id);
        newArr.push({ email: user.id, role: newRole });
      }

      return [...newArr];
    };

    isBulkEditing
      ? setBulkUserEdits(updateCollection)
      : setBulkUserInvites(updateCollection);
  };

  const handleDialog = (actionVar) => {
    if (actionVar === "invite") {
      setRoleDialogOpen(true);
    }
  };
  const handleBulkEditPost = async () => {
    setIsLoading(true);
    try {
      const response = await axiosInstance.post(`/bulk/edit`, {
        users: bulkUserEdits,
      });

      const data = response.data.users;

      let userList = Object.keys(data).map((email) => ({
        id: email,
        email,
        name: data[email].Name ? data[email].Name : "",
        role: data[email].Role === "NotSet" ? "" : data[email].Role,
        password: data[email].Password,
        isActive: data[email].IsActive,
        isHidden: data[email].IsHidden,
        isPermanent: data[email].IsPermanent,
      }));

      userList = userList.map((user) => ({
        ...user,
        status: determineStatus(user.role, user.password),
      }));

      if (!showHiddenUsers) {
        userList = userList.filter((user) => !user.isHidden);
      }

      setUsers(userList);
      setIsBulkEditing(false);
      setBulkUserEdits([]);
      setRowSelectionModel([]);

      setMessage("Successfully edited users!");
      setIsError(false);
    } catch (error) {
      setMessage("Error during bulk edit:", error);
      setIsError(true);
      setRoleDialogOpen(false);
    } finally {
      setIsLoading(false);
      setSnackBarOpen(true);
    }
  };

  const handleBulkInvitePost = async () => {
    setIsLoading(true);
    try {
      const response = await axiosInstance.post(`/bulk/invite`, {
        users: bulkUserInvites,
      });

      const data = response.data.users;

      let userList = Object.keys(data).map((email) => ({
        id: email,
        email,
        name: data[email].Name ? data[email].Name : "",
        role: data[email].Role === "NotSet" ? "" : data[email].Role,
        password: data[email].Password,
        isActive: data[email].IsActive,
        isHidden: data[email].IsHidden,
        isPermanent: data[email].IsPermanent,
      }));

      userList = userList.map((user) => ({
        ...user,
        status: determineStatus(user.role, user.password),
      }));

      if (!showHiddenUsers) {
        userList = userList.filter((user) => !user.isHidden);
      }

      setUsers(userList);
      setIsConfirmingBulkInvite(false);
      setIsBulkInviting(false);
      setBulkUserInvites([]);
      setRowSelectionModel([]);

      setMessage("Successfully edited users!");
      setIsError(false);
    } catch (error) {
      setMessage("Error during bulk invite:", error);
      setIsError(true);
    } finally {
      setIsLoading(false);
      setSnackBarOpen(true);
    }
  };

  const handleAction = async (actionType, user, individualInviteId = null) => {
    setIsLoading(true);
    try {
      const response = await axiosInstance.post(
        `/user/${user.email}/${actionType}`,
        {
          user: user,
        }
      );

      if (parseInt(response.status) === 201) {
        const user = response.data.user;

        setUsers((prevUsers) => {
          const updatedUsers = prevUsers.map((existingUser) =>
            existingUser.email === user.Email
              ? {
                  ...existingUser,
                  role: user.Role,
                  status: determineStatus(
                    user.Role,
                    existingUser.Password ? existingUser.Password : ""
                  ),
                }
              : existingUser
          );

          return updatedUsers;
        });
        setMessage("Successfully performed user action!");
        setIsError(false);
      }

      setRoleDialogOpen(false);
    } catch (error) {
      setMessage("Error during user action:", error);
      setIsError(true);
    } finally {
      setIsLoading(false);
      setSnackBarOpen(true);
    }
  };

  useEffect(() => {
    setIsLoading(true);
    fetchLogs();
    fetchUsers();
    if (!showHiddenUsers) {
      setFilterModel({
        items: [
          {
            field: "isHidden",
            operator: "equals",
            value: "false",
          },
        ],
      });
    } else {
      setFilterModel({ items: [] });
    }
  }, [showHiddenUsers]);
  useEffect(() => {
    if (isBulkInviting === false && bulkUserInvites.length) {
      setBulkUserInvites([]);
      setRowSelectionModel([]);
    }
  }, [isBulkInviting]);
  return (
    <Container className={classes.container}>
      <Grid
        container
        spacing={2}
        alignItems="center">
        <Grid
          item
          xs={12}
          sm={12}>
          <Tabs
            value={selectedTab}
            onChange={handleTabChange}
            centered
            sx={{ margin: "7.8rem 0 1.6rem 0" }}>
            {" "}
            <Tab label="User" />
            <Tab label="Log Viewer" />
          </Tabs>
        </Grid>

        {selectedTab === 1 && (
          <Grid
            item
            xs={12}>
            <LogViewer
              logs={logs}
              sortModel={sortModel}
              filterModel={filterModel}
              setSortModel={setSortModel}
              setFilterModel={setFilterModel}
            />
          </Grid>
        )}

        {selectedTab === 0 && (
          <Grid
            item
            xs={12}>
            <UserManagement
              filteredUsers={
                isBulkInviting
                  ? filteredUsers.filter(
                      (user) => user.status === "Not Invited"
                    )
                  : filteredUsers
              }
              rowSelectionModel={rowSelectionModel}
              setRowSelectionModel={setRowSelectionModel}
              sortModel={sortModel}
              setSortModel={setSortModel}
              searchTerm={searchTerm}
              setSearchTerm={setSearchTerm}
              showHiddenUsers={showHiddenUsers}
              setShowHiddenUsers={setShowHiddenUsers}
              isLoading={isLoading}
              isBulkEditing={isBulkEditing}
              isBulkInviting={isBulkInviting}
              setBulkUserEdits={setBulkUserEdits}
              setBulkUserInvites={setBulkUserInvites}
              handleBulkEditPost={handleBulkEditPost}
              handleBulkInvitePost={handleBulkInvitePost}
              setIsBulkEditing={setIsBulkEditing}
              setIsBulkInviting={setIsBulkInviting}
              users={users}
              filterModel={filterModel}
              setFilterModel={setFilterModel}
              bulkUserInvites={bulkUserInvites}
              bulkUserEdits={bulkUserEdits}
              determineStatus={determineStatus}
              handleRoleChange={handleRoleChange}
              handleAction={handleAction}
              setIsConfirmingBulkInvite={setIsConfirmingBulkInvite}
              setIsHidingUsers={setIsHidingUsers}
              isHidingUsers={isHidingUsers}
            />
          </Grid>
        )}
      </Grid>
      <Dialog
        open={isConfirmingBulkInvite && rowSelectionModel.length}
        onClose={() => {
          setRoleDialogOpen(false);
        }}
        aria-labelledby="confirmation-dialog-title"
        aria-describedby="confirmation-dialog-description">
        <DialogTitle id="confirmation-dialog-title">Invite Users</DialogTitle>
        <DialogContent>
          <DialogContentText id="confirmation-dialog-description">
            {rowSelectionModel.length === 0
              ? "No users selected."
              : "Are you sure you want to invite the selected users?"}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setIsConfirmingBulkInvite(false)}
            color="primary">
            Cancel
          </Button>
          <Button
            onClick={async () => {
              await handleBulkInvitePost();
            }}
            color="primary"
            disabled={rowSelectionModel.length === 0}>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
}

export default Admin;
