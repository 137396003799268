import React, { useState } from "react";
import {
  Typography,
  Container,
  CircularProgress,
  Box,
  Divider,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Alert,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  List,
  ListItem,
} from "@mui/material";

import ChangePaymentMethodForm from "../Stripe/ChangePaymentMethodForm";
import designSystem from "../../theme";

const cancellationReasons = [
  "Cost / Budget",
  "Usage Effectiveness",
  "Not Satisfied with Service",
  "Not Needed",
  "Other",
];

const BillingDialog = ({
  stripe,
  classes,
  openDialog,
  successMessage,
  showCountdownDialog,
  countdown,
  confirmClicked,
  seatError,
  handleUpdateSeats,
  handleCancelSubscription,
  setOpenDialog,
  seatCount,
  setSeatCount,
  subscriptionDetails,
  stripeData,
  setNewPaymentMethodId,
  handleUpdateBilling,
  deleteConfirmation,
  setDeleteConfirmation,
  setIsDeleteConfirmationValid,
  setConfirmClicked,
  setCancellationReason,
  cancellationReason,
  isDeleteConfirmationValid,
  requiredText,
  setRequiredText,
  setNewSeatCount,
  newSeatCount,
  requiredTextError,
  cancellationReasonError,
  setRequiredTextError,
  setCancellationReasonError,
}) => {
  const [cancellationStep, setCancellationStep] = useState(1);
  const [updateSeatsStep, setUpdateSeatsStep] = useState(1);

  const renderFirstStep = () => (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        width: "100%",
      }}>
      <Typography
        variant="body1"
        sx={{ mt: 2 }}>
        By canceling your subscription, you'll miss out on the following
        benefits:
      </Typography>
      <List sx={{ fontSize: designSystem.typography.body2 }}>
        <ListItem>
          - Real-Time Dashboard: Effortlessly manage active licenses.
        </ListItem>
        <ListItem>
          - Insightful Reporting: Gain valuable insights into license usage.
        </ListItem>
        <ListItem>
          - Admin Panel: Simplify the management of Salesforce licenses.
        </ListItem>
        <ListItem>
          - Easy Setup: Get up and running in just 15 minutes.
        </ListItem>
        <ListItem>
          - Secure Integration: Connect securely with Salesforce.
        </ListItem>
      </List>
      <Typography
        variant="body2"
        color="error"
        sx={{ mt: 2 }}
        align="center">
        Are you certain you want to proceed? This will disable SeatSwapper, and
        you'll lose these valuable features!
      </Typography>
    </Box>
  );

  const renderSecondStep = () => (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          width: "100%",
        }}>
        <Typography
          variant="body2"
          color="error"
          sx={{ mt: 2 }}>
          This action is irreversible.
        </Typography>
        <Typography
          variant="body1"
          color="error"
          sx={{ mb: 2, mt: 2 }}>
          To confirm cancellation, type "DELETE":
        </Typography>
      </Box>
      <TextField
        type="text"
        value={deleteConfirmation}
        onChange={(e) => {
          setDeleteConfirmation(e.target.value);
          setIsDeleteConfirmationValid(e.target.value === "DELETE");
        }}
        fullWidth
        sx={{ mb: 2 }}
      />
      <Divider sx={{ my: 2 }} />
      <FormControl
        fullWidth
        sx={{ mb: 2, mt: 2 }}>
        <InputLabel htmlFor="cancellation-reason">
          Reason for Cancelling *
        </InputLabel>
        <Select
          value={cancellationReason}
          onChange={(e) => {
            setCancellationReason(e.target.value);
            setCancellationReasonError("");
          }}
          inputProps={{
            name: "cancellationReason",
            id: "cancellation-reason",
          }}
          fullWidth>
          {cancellationReasons.map((reason, index) => (
            <MenuItem
              key={index}
              value={reason}>
              {reason}
            </MenuItem>
          ))}
        </Select>
        {cancellationReasonError && (
          <Typography
            variant="caption"
            color="error"
            sx={{ mt: 1 }}>
            {cancellationReasonError}
          </Typography>
        )}
      </FormControl>

      <TextField
        multiline
        rows={3}
        value={requiredText}
        onChange={(e) => {
          setRequiredText(e.target.value);
          setRequiredTextError("");
        }}
        fullWidth
        placeholder="Tell Us Why? (required)"
        sx={{ mb: 2 }}
      />
      {requiredTextError && (
        <Typography
          variant="caption"
          color="error"
          sx={{ mt: 1 }}>
          {requiredTextError}
        </Typography>
      )}
    </>
  );

  const renderDialogContent = () => {
    if (openDialog === "Update Seats") {
      if (updateSeatsStep === 1) {
        return (
          <div>
            <Typography
              margin="1.6rem 0 0 0"
              variant="h5"
              fontWeight="bold"
              gutterBottom>
              Current: {seatCount}
            </Typography>
            <TextField
              type="number"
              value={newSeatCount}
              placeholder="New desired number of seats"
              onChange={(e) => {
                setNewSeatCount(e.target.value);
              }}
              InputProps={{
                inputProps: {
                  min: 1,
                  max: 39999,
                },
              }}
              fullWidth
              sx={{ mb: 2 }}
            />
          </div>
        );
      } else {
        return (
          <div>
            <Typography
              variant="h5"
              align="center"
              gutterBottom>
              Are you sure you want to change seats from {seatCount} to{" "}
              {newSeatCount}?
            </Typography>
          </div>
        );
      }
    } else if (openDialog === "Change Billing") {
      return (
        <>
          <ChangePaymentMethodForm
            clientSecret={stripeData.clientSecret}
            stripe={stripe}
            customerId={subscriptionDetails.customer_id}
            subscriptionId={subscriptionDetails.subscription_id}
            setNewPaymentMethodId={setNewPaymentMethodId}
            handleUpdateBilling={handleUpdateBilling}
            confirmClicked={confirmClicked}
            setConfirmClicked={setConfirmClicked}
          />
        </>
      );
    } else if (openDialog === "Cancel") {
      return cancellationStep === 1 ? renderFirstStep() : renderSecondStep();
    }
    return `Are you sure you want to ${openDialog}?This will stop Seat Swapper from working!`;
  };

  return (
    <Container className={classes.container}>
      {((openDialog === "Change Billing" && stripeData) ||
        openDialog === "Cancel" ||
        openDialog === "Update Seats") && (
        <Dialog
          open={openDialog !== null}
          onClose={() => !confirmClicked && setOpenDialog(null)}
          maxWidth="lg">
          {successMessage && (
            <Alert
              severity="success"
              sx={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                zIndex: 2,
              }}>
              {successMessage}
            </Alert>
          )}
          {showCountdownDialog && (
            <Dialog
              open={true}
              maxWidth="sm"
              fullWidth>
              <DialogTitle>We're sorry to see you go!</DialogTitle>
              <DialogContent>
                <Typography variant="body1">
                  Your sensitive Salesforce data is being deleted from our
                  databases, and your app is being removed!
                </Typography>
                <Typography variant="body1">
                  Redirecting you to{" "}
                  <a href="https://seatswapper.io/pages/cancellation">
                    https://seatswapper.io/pages/cancellation
                  </a>
                  in {countdown} seconds.
                </Typography>
              </DialogContent>
            </Dialog>
          )}
          {confirmClicked && !successMessage && (
            <Box
              position="absolute"
              top={0}
              left={0}
              width="100%"
              height="100%"
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              bgcolor="rgba(255, 255, 255, 0.8)"
              zIndex={2}>
              <CircularProgress />
              <Typography
                variant="body2"
                sx={{ marginTop: 2 }}>
                Processing...
              </Typography>
            </Box>
          )}

          <DialogTitle
            variant="h4"
            align="center">
            {openDialog}
          </DialogTitle>
          <DialogContent
            sx={{
              minWidth: "380px",
              maxWidth: "100%",
              margin: "0 auto",
              border: "1px solid #ddd",
              borderRadius: "8px",
              padding: "16px",
            }}>
            {successMessage ? null : seatError ? (
              <Typography color="error">{seatError}</Typography>
            ) : (
              renderDialogContent()
            )}
          </DialogContent>
          <DialogActions>
            {!successMessage && (
              <>
                <Button
                  onClick={() => setOpenDialog(null)}
                  color="primary"
                  disabled={confirmClicked}>
                  Cancel
                </Button>
                <Button
                  disabled={
                    confirmClicked ||
                    (openDialog === "Cancel" &&
                      cancellationStep === 2 &&
                      (!isDeleteConfirmationValid ||
                        !cancellationReason ||
                        !requiredText)) ||
                    (openDialog === "Update Seats" &&
                      (!newSeatCount || seatCount === newSeatCount))
                  }
                  onClick={
                    openDialog === "Change Billing"
                      ? () => {
                          setConfirmClicked(true);
                        }
                      : openDialog === "Update Seats"
                      ? updateSeatsStep === 1
                        ? () => setUpdateSeatsStep(2)
                        : handleUpdateSeats
                      : cancellationStep === 1
                      ? () => setCancellationStep(2)
                      : handleCancelSubscription
                  }
                  color="primary">
                  {(openDialog === "Cancel" && cancellationStep === 1) ||
                  (openDialog === "Update Seats" && updateSeatsStep === 1)
                    ? "Proceed"
                    : "Save"}
                </Button>
              </>
            )}
          </DialogActions>
        </Dialog>
      )}
    </Container>
  );
};

export default BillingDialog;
