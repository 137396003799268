import React from "react";
import { CardElement } from "@stripe/react-stripe-js";
import "./Styles.css";
import { Typography } from "@mui/material";

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: "#32325d",
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: "antialiased",
      fontSize: "16px",
      "::placeholder": {
        color: "#aab7c4",
      },
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a",
    },
  },
};

function CardSection({ handleCardChange }) {
  const handleChange = (event) => {
    handleCardChange(event);
  };

  return (
    <div>
      <Typography
        variant="h5"
        fontWeight="bold"
        gutterBottom>
        Card details
      </Typography>
      <CardElement
        options={CARD_ELEMENT_OPTIONS}
        onChange={handleChange}
      />
    </div>
  );
}

export default CardSection;
