import React, { useEffect, useState } from "react";
import {
  Typography,
  Container,
  Paper,
  CircularProgress,
  Box,
  Divider,
  Grid,
  Button,
} from "@mui/material";

import { DataGrid } from "@mui/x-data-grid";
import { useAuth } from "../context/AuthContext";
import { makeStyles } from "@mui/styles";

import BillingDialog from "../components/Billing/BillingDialog";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: "0 8px !important",

    marginLeft: "0%",
    width: "80%",
    [theme.breakpoints.up("md")]: {
      marginLeft: "auto !important",
      width: "100% !important",
      paddingLeft: "14% !important",
    },
  },
  card: {
    marginBottom: "20px",
    padding: "10px",
  },
  actionButton: {
    width: "100%",
  },
  activeUserCard: {
    backgroundColor: "#e8f5e9",
    position: "relative",
  },
  inactiveUserCard: {
    backgroundColor: "#ffebee",
    position: "relative",
  },
  dataGrid: {
    border: "1px solid #e0e0e0",
    "& .MuiDataGrid-header": {
      backgroundColor: "#f5f5f5",
    },
    "& .MuiDataGrid-row": {
      "&:nth-of-type(odd)": {
        backgroundColor: "#f9f9f9",
      },
      "&.Mui-selected": {
        backgroundColor: "#ffc107",
      },
    },
  },

  customToolbar: {
    display: "flex",
    justifyContent: "space-between",
    padding: "10px",
    backgroundColor: "#f5f5f5",
    borderBottom: "1px solid #e0e0e0",
  },
  customPagination: {
    backgroundColor: "#f5f5f5",
  },
}));

export default function Billing({
  stripe,
  setIsError,
  setMessage,
  setSnackBarOpen,
  snackBarOpen,
  message,
  isError,
}) {
  const [loading, setLoading] = useState(true);
  const [newPaymentMethodId, setNewPaymentMethodId] = useState(null);
  const [subscriptionDetails, setSubscriptionDetails] = useState({
    transactions: {
      transactions: [],
    },
  });
  const [stripeData, setStripeData] = useState(null);
  const [openDialog, setOpenDialog] = useState(null);
  const [seatCount, setSeatCount] = useState(0);
  const [newSeatCount, setNewSeatCount] = useState(null);
  const [seatError, setSeatError] = useState(null);

  const [confirmClicked, setConfirmClicked] = useState(false);
  const [successMessage, setSuccessMessage] = useState(null);
  const [deleteConfirmation, setDeleteConfirmation] = useState("");

  const [isDeleteConfirmationValid, setIsDeleteConfirmationValid] =
    useState(false);
  const [cancellationReason, setCancellationReason] = useState("");

  const [showCountdownDialog, setShowCountdownDialog] = useState(false);
  const [requiredText, setRequiredText] = useState("");
  const [countdown, setCountdown] = useState(null);
  const [cancellationReasonError, setCancellationReasonError] = useState("");
  const [requiredTextError, setRequiredTextError] = useState("");
  const [totalActiveUsers, setTotalActiveUsers] = useState(null);
  const classes = useStyles();
  const { axiosInstance } = useAuth();

  const handleButtonClick = (type) => {
    if (type === "Update Seats") {
      setSeatCount(subscriptionDetails.quantity);
      setSeatError(null);
    }
    setOpenDialog(type);
  };

  const handleUpdateSeats = async () => {
    try {
      if (newSeatCount < totalActiveUsers) {
        throw new Error("Seat count cannot be less than total active users!");
      }
      const response = await axiosInstance.post(
        `/seats/${subscriptionDetails.subscription_id}`,
        {
          new_quantity: newSeatCount,
        }
      );

      if (!response.status === 200) {
        throw new Error("There was an error updating the seats.");
      }

      const data = response.data;

      if (data.success) {
        setSubscriptionDetails((prevDetails) => ({
          ...prevDetails,
          quantity: newSeatCount,
        }));
        setSeatCount(newSeatCount);
        setNewSeatCount(null);
        setOpenDialog(null);
        setMessage("Successfully updated seats!");
        setIsError(false);
      } else {
        setSeatError("Reduce your active seats first.");
      }
    } catch (error) {
      setSeatError(error.message);
      setMessage(error.message);
      setIsError(true);
    } finally {
      setSnackBarOpen(true);
    }
  };

  const handleUpdateBilling = async () => {
    try {
      const { data } = await axiosInstance.patch(
        `/billing/${subscriptionDetails.subscription_id}`,
        {
          customer_id: subscriptionDetails.customer_id,
          newPaymentMethodId: newPaymentMethodId,
        }
      );

      if (!data.success) {
        throw new Error("Failed to update card on the backend");
      }

      setSuccessMessage("Billing details updated successfully!");

      setNewPaymentMethodId(null);
      setMessage("Billing details updated successfully!");
      setIsError(false);
      setTimeout(() => {
        setConfirmClicked(false);
        setOpenDialog(null);
        setSuccessMessage(null);
      }, 2000);
    } catch (error) {
      setMessage("Error updating billing details:", error);
      setIsError(true);
    } finally {
      setSnackBarOpen(true);
    }
  };

  const handleCancelSubscription = async () => {
    try {
      if (!cancellationReason) {
        setCancellationReasonError("Please select a cancellation reason.");
        return;
      }

      if (!requiredText) {
        setOptionalTextError("Please provide a reason for cancellation.");
        return;
      }

      const { data } = await axiosInstance.post(
        `/billing/${subscriptionDetails.subscription_id}`,
        { reason: cancellationReason, requiredText: requiredText }
      );

      if (!data.success) {
        throw new Error("Failed to cancel subscription on the backend");
      }

      setSuccessMessage("Subscription successfully canceled!");
      setMessage("Subscription successfully canceled!");
      setIsError(false);
      setShowCountdownDialog(true);

      setCountdown(15);

      const countdownInterval = setInterval(() => {
        setCountdown((prevCountdown) => prevCountdown - 1);
      }, 1000);

      setTimeout(() => {
        setConfirmClicked(false);
        setOpenDialog(null);
        setSuccessMessage(null);
        window.location.href = "https://seatswapper.io/pages/cancellation";
      }, 15000);
    } catch (error) {
      setMessage("Error canceling subscription:", error);
      setIsError(true);
    } finally {
      setSnackBarOpen(true);
    }
  };
  useEffect(() => {
    async function fetchData() {
      try {
        const { data } = await axiosInstance.get("/billing");

        setSubscriptionDetails(data);
        let userList = Object.keys(data.users)
          .map((email) => ({
            isActive: data.users[email].IsActive,
          }))
          .filter((user) => user.isActive);
        setTotalActiveUsers(userList.length);

        setLoading(false);
      } catch (error) {
        setMessage("Error fetching data:", error);
        setIsError(true);
        setSnackBarOpen(true);
      }
    }

    fetchData();
  }, []);
  useEffect(() => {
    async function fetchData() {
      try {
        const { data } = await axiosInstance.get("/billing/config");

        setStripeData(data);

        setLoading(false);
      } catch (error) {
        setMessage("Error fetching data:", error);
        setIsError(true);
        setSnackBarOpen(true);
      }
    }
    if (openDialog && !stripeData) {
      fetchData();
    } else if (!openDialog && stripeData) {
      setStripeData(null);
    }
  }, [openDialog]);
  useEffect(() => {
    if (confirmClicked && newPaymentMethodId) {
      handleUpdateBilling();
    }
  }, [newPaymentMethodId]);

  const columns = [
    { field: "id", headerName: "Transaction ID", width: 200 },
    {
      field: "time",
      headerName: "Date Paid",
      width: 170,
      valueGetter: (params) =>
        new Date(params.row.created).toLocaleDateString(),
    },
    { field: "status", headerName: "Status", width: 130 },
    {
      field: "amount",
      headerName: "Amount",
      width: 170,
      valueGetter: (params) => {
        return `${params.row.amount} ${params.row.currency}`;
      },
    },
  ];

  if (loading) {
    return (
      <Container>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="80vh">
          <CircularProgress />
        </Box>
      </Container>
    );
  }

  return (
    <Container className={classes.container}>
      <Grid
        my={8}
        alignItems="center">
        <Grid
          mb={2}
          display="flex"
          justifyContent="flex-end">
          <Box mr={2}>
            <Button
              variant="contained"
              color="primary"
              disabled={loading}
              onClick={() => handleButtonClick("Change Billing")}>
              Edit Billing
            </Button>
          </Box>
          <Button
            variant="contained"
            color="primary"
            disabled={loading}
            onClick={() => handleButtonClick("Update Seats")}>
            Update Seats
          </Button>
        </Grid>
        <Paper
          elevation={3}
          style={{ padding: "20px" }}>
          <Grid
            container
            spacing={3}>
            <Grid
              item
              xs={12}
              md={6}>
              <Typography
                variant="h6"
                color="textSecondary">
                Subscriber Name
              </Typography>
              <Typography variant="body2">
                {subscriptionDetails.subscriber_name}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}>
              <Typography
                variant="h6"
                color="textSecondary">
                Subscriber Email
              </Typography>
              <Typography variant="body2">
                {subscriptionDetails.subscriber_email}
              </Typography>
            </Grid>
          </Grid>

          <Divider style={{ margin: "20px 0" }} />

          <Typography
            variant="h5"
            gutterBottom
            align="left">
            Subscription Details
          </Typography>
          <Grid
            container
            spacing={3}>
            <Grid
              item
              xs={12}>
              <Typography
                variant="h6"
                color="textSecondary">
                Subscription ID
              </Typography>
              <Typography variant="body2">
                {subscriptionDetails.subscription_id}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}>
              <Typography
                variant="h6"
                color="textSecondary">
                Plan
              </Typography>
              <Typography variant="body2">
                {subscriptionDetails.plan}
              </Typography>
            </Grid>
            <Grid
              item
              xs={6}>
              <Typography
                variant="h6"
                color="textSecondary">
                Status
              </Typography>
              <Typography variant="body2">
                {subscriptionDetails.status} {subscriptionDetails.type}
              </Typography>
            </Grid>
            <Grid
              item
              xs={6}>
              <Typography
                variant="h6"
                color="textSecondary">
                Seats
              </Typography>
              <Typography variant="body2">
                {subscriptionDetails.quantity}
              </Typography>
            </Grid>
            <Grid
              item
              xs={6}>
              <Typography
                variant="h6"
                color="textSecondary">
                Start Date
              </Typography>
              <Typography variant="body2">
                {new Date(
                  subscriptionDetails.created_time * 1000
                ).toLocaleDateString()}
              </Typography>
            </Grid>
            <Grid
              item
              xs={6}>
              <Typography
                variant="h6"
                color="textSecondary">
                Next Billing Date
              </Typography>
              <Typography variant="body2">
                {new Date(
                  subscriptionDetails.next_billing_time * 1000
                ).toLocaleDateString()}
              </Typography>
            </Grid>
          </Grid>

          <Divider style={{ margin: "20px 0" }} />

          <Typography
            variant="h5"
            gutterBottom
            align="left">
            Billing Details
          </Typography>
          <Typography
            variant="h6"
            color="textSecondary">
            Outstanding Balance
            <Typography
              variant="body2"
              display="block"
              color="#000">
              {subscriptionDetails.outstanding_balance}$
            </Typography>
          </Typography>

          <Divider style={{ margin: "20px 0" }} />

          <Typography
            variant="h5"
            gutterBottom
            align="left">
            Transactions
          </Typography>

          <Box style={{ height: 400, width: "100%" }}>
            {subscriptionDetails.transactions &&
            subscriptionDetails.transactions.data &&
            subscriptionDetails.transactions.data.length > 0 ? (
              <DataGrid
                rows={subscriptionDetails.transactions.data}
                columns={columns}
                pageSize={5}
                rowsPerPageOptions={[5]}
                getRowId={(row) => row.id}
              />
            ) : (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                height="100%">
                <Typography
                  variant="body2"
                  color="textSecondary">
                  No transactions yet
                </Typography>
              </Box>
            )}
          </Box>
          <Box
            mt={3}
            display="flex"
            justifyContent="left">
            <Box mr={2}>
              <Button
                disabled={confirmClicked}
                variant="contained"
                color="error"
                onClick={() => {
                  setOpenDialog("Cancel");
                  setDeleteConfirmation("");
                }}>
                Cancel subscription
              </Button>
            </Box>
          </Box>
        </Paper>
      </Grid>
      {((openDialog === "Change Billing" && stripeData) ||
        openDialog === "Cancel" ||
        openDialog === "Update Seats") && (
        <BillingDialog
          stripe={stripe}
          loading={loading}
          classes={classes}
          openDialog={openDialog}
          successMessage={successMessage}
          showCountdownDialog={showCountdownDialog}
          countdown={countdown}
          confirmClicked={confirmClicked}
          seatError={seatError}
          handleUpdateSeats={handleUpdateSeats}
          handleCancelSubscription={handleCancelSubscription}
          setSeatCount={setSeatCount}
          seatCount={seatCount}
          setNewSeatCount={setNewSeatCount}
          newSeatCount={newSeatCount}
          subscriptionDetails={subscriptionDetails}
          stripeData={stripeData}
          setNewPaymentMethodId={setNewPaymentMethodId}
          handleUpdateBilling={handleUpdateBilling}
          deleteConfirmation={deleteConfirmation}
          setDeleteConfirmation={setDeleteConfirmation}
          setIsDeleteConfirmationValid={setIsDeleteConfirmationValid}
          isDeleteConfirmationValid={isDeleteConfirmationValid}
          setConfirmClicked={setConfirmClicked}
          setCancellationReason={setCancellationReason}
          cancellationReason={cancellationReason}
          setOpenDialog={setOpenDialog}
          requiredText={requiredText}
          setRequiredText={setRequiredText}
          requiredTextError={requiredTextError}
          cancellationReasonError={cancellationReasonError}
          setRequiredTextError={setRequiredTextError}
          setCancellationReasonError={setCancellationReasonError}
        />
      )}
    </Container>
  );
}
