import React, { useState, useEffect } from "react";
import {
  Grid,
  TextField,
  Paper,
  Button,
  Typography,
  InputAdornment,
  Select,
  MenuItem,
  InputLabel,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../context/AuthContext";

import LockIcon from "@mui/icons-material/Lock";

export default function FirstTimeLoginForm({
  users,
  authenticateUser,
  setIsError,
  setMessage,
  setSnackBarOpen,
}) {
  const [selectedUserId, setSelectedUserId] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");

  const navigate = useNavigate();
  const { axiosInstance } = useAuth();

  const handleFirstLogin = async (event) => {
    event.preventDefault();

    if (
      !selectedUserId ||
      password.length < 8 ||
      password !== passwordConfirmation
    ) {
      setError(
        "Please select a valid user, provide a password with at least 8 characters, and ensure password confirmation matches."
      );
      setMessage(
        "Please select a valid user, provide a password with at least 8 characters, and ensure password confirmation matches."
      );
      setIsError(true);
      setSnackBarOpen(true);
      return;
    }

    setIsLoading(true);

    try {
      const success = await authenticateUser(null, password, selectedUserId);

      setIsLoading(false);

      if (success) {
        setMessage("Password set successfully, Welcome to Seat Swapper!");
        setIsError(false);
        setSnackBarOpen(true);
        navigate("/users");
      } else {
        setError("Invalid password.");
      }
    } catch (caughtError) {
      setIsLoading(false);
      setError(caughtError.message);
    }
  };

  useEffect(() => {
    if (users && users.length) {
      setSelectedUserId(users[0].id);
    }
  }, [users]);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}>
      <Paper
        elevation={3}
        style={{ padding: "30px", maxWidth: 500 }}>
        <form onSubmit={handleFirstLogin}>
          <Grid
            container
            spacing={2}
            direction="column"
            justifyContent="center"
            alignItems="center">
            <Grid
              item
              xs={12}
              style={{ textAlign: "center" }}>
              <img
                src="/img/base_logo.png"
                alt="Your Logo"
                width="100"
                style={{ marginBottom: "10px" }}
              />
              <Typography
                variant="h4"
                style={{ color: "#333" }}>
                Admin Registration
              </Typography>
            </Grid>

            <Grid
              item
              xs={12}
              md={12}>
              <InputLabel id="user-select-label">Select User</InputLabel>
              <Select
                labelId="user-select-label"
                id="user-select"
                value={selectedUserId}
                label="Select User"
                onChange={(e) => setSelectedUserId(e.target.value)}
                fullWidth>
                {users &&
                  users.map((user) => (
                    <MenuItem
                      key={user.id}
                      value={user.id}>
                      {user.email}
                    </MenuItem>
                  ))}
              </Select>
            </Grid>

            <Grid
              item
              xs={12}>
              <TextField
                fullWidth
                label="Password"
                type="password"
                value={password}
                size="small"
                onChange={(e) => setPassword(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <LockIcon />
                    </InputAdornment>
                  ),
                }}
                sx={{ marginBottom: "1rem" }}
              />
            </Grid>
            <Grid
              item
              xs={12}>
              <TextField
                fullWidth
                label="Password Confirmation"
                type="password"
                value={passwordConfirmation}
                size="small"
                onChange={(e) => setPasswordConfirmation(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <LockIcon />
                    </InputAdornment>
                  ),
                }}
                sx={{ marginBottom: "1rem" }}
              />
            </Grid>
            <Grid
              item
              xs={12}>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                size="large"
                disabled={
                  isLoading ||
                  password.length < 8 ||
                  password !== passwordConfirmation ||
                  !selectedUserId
                }>
                {isLoading ? "Processing..." : "Submit"}
              </Button>
            </Grid>
            {error && (
              <Grid
                item
                xs={12}
                style={{ marginTop: "1rem" }}>
                <Typography color="error">{error}</Typography>
              </Grid>
            )}
          </Grid>
        </form>
      </Paper>
    </div>
  );
}
