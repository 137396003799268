import React, { useEffect, useState } from "react";
import {
  Grid,
  TextField,
  Paper,
  Button,
  Typography,
  Divider,
  InputAdornment,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../context/AuthContext";
import { validateEmail } from "../utils/helper";

import LockIcon from "@mui/icons-material/Lock";
import EmailIcon from "@mui/icons-material/Email";

export default function LoginForm({ setIsError, setMessage, setSnackBarOpen }) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isForgotPassword, setIsForgotPassword] = useState(false);

  const navigate = useNavigate();
  const { authenticateUser, axiosInstance, token, role } = useAuth();

  let buttonText;
  if (isLoading) {
    if (isForgotPassword) {
      buttonText = "Sending...";
    } else {
      buttonText = "Logging in...";
    }
  } else if (isForgotPassword) {
    buttonText = "Submit";
  } else {
    buttonText = "Login";
  }

  const handleLoginOrReset = async (event) => {
    event.preventDefault();

    if (!email || !validateEmail(email)) {
      setError("Please provide a valid email address.");
      setMessage("Please provide a valid email address.");
      setIsError(true);
      setSnackBarOpen(true);
      return;
    }

    setIsLoading(true);

    if (isForgotPassword) {
      try {
        const response = await axiosInstance.post("/password_reset", { email });

        if (response.status === 200) {
          const data = response.data;
          if (data.success) {
            setError("Password reset link sent to email.");
            setMessage("Password reset link sent to email.");
            setIsError(false);
            setSnackBarOpen(true);
          } else {
            setError(data.message || "Password reset failed.");
            setMessage(data.message || "Password reset failed.");
            setIsError(true);
            setSnackBarOpen(true);
          }
        } else {
          setError("Server responded with an error.");
          setMessage("Server responded with an error.");
          setIsError(true);
          setSnackBarOpen(true);
        }
      } catch (error) {
        setError(error.message);
        setMessage(error.message);
        setIsError(true);
        setSnackBarOpen(true);
      } finally {
        setIsLoading(false);
      }
    } else {
      try {
        const success = await authenticateUser(email, password);
        setIsLoading(false);

        if (success) {
          navigate("/users");
        } else {
          setError("Authentication failed.");
          setMessage("Authentication failed.");
          setIsError(true);
          setSnackBarOpen(true);
        }
      } catch (caughtError) {
        setIsLoading(false);
        setError(caughtError.message);
        setMessage(caughtError.message);
        setIsError(true);
        setSnackBarOpen(true);
      }
    }
  };
  useEffect(() => {
    if (token && role) {
      navigate("/users");
    }
  }, [token, role]);
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}>
      <Paper
        elevation={3}
        style={{ padding: "30px", maxWidth: 500 }}>
        <form onSubmit={handleLoginOrReset}>
          <Grid
            container
            spacing={2}
            direction="column"
            justifyContent="center"
            alignItems="center">
            <Grid
              item
              xs={12}
              style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
                paddingTop: "20px",
              }}>
              <img
                src="/img/base_logo.png"
                alt="Your Logo"
                width="100"
                style={{
                  marginBottom: "10px",
                }}
              />
              <Typography
                variant="h4"
                style={{ color: "#333" }}>
                Seat Swapper
              </Typography>
            </Grid>

            <Grid
              item
              xs={12}
              style={{ textAlign: "center" }}>
              <Typography
                variant="subtitle1"
                color="textSecondary">
                {isForgotPassword
                  ? "Recover Password"
                  : "Sign In to your Account"}
              </Typography>
            </Grid>

            <Grid
              item
              xs={6}>
              <TextField
                fullWidth
                label="Email"
                value={email}
                size="small"
                onChange={(e) => setEmail(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <EmailIcon />
                    </InputAdornment>
                  ),
                }}
                sx={{ height: "40px", marginBottom: "1rem" }}
              />
            </Grid>
            {!isForgotPassword && (
              <Grid
                item
                xs={6}>
                <TextField
                  fullWidth
                  label="Password"
                  type="password"
                  value={password}
                  size="small"
                  onChange={(e) => setPassword(e.target.value)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <LockIcon />
                      </InputAdornment>
                    ),
                  }}
                  sx={{ height: "40px", marginBottom: "1rem" }}
                />
              </Grid>
            )}
            <Grid
              item
              xs={12}
              sx={{ display: "flex", alignContent: "flex-end", width: "100%" }}>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                size="large"
                disabled={isLoading}>
                {buttonText}
              </Button>
            </Grid>
            {error && (
              <Grid
                item
                xs={12}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "1rem",
                }}>
                <Typography color="error">{error}</Typography>
              </Grid>
            )}
            <Divider sx={{ width: "100%", margin: "1.2rem 0" }} />
            <Grid
              item
              xs={12}
              style={{ display: "flex", justifyContent: "center" }}>
              <a
                href="#forgot"
                style={{ textDecoration: "none" }}
                onClick={(e) => {
                  e.preventDefault();
                  setIsForgotPassword(!isForgotPassword);
                }}>
                {isForgotPassword ? "Back to Login" : "Forgot Password?"}
              </a>
            </Grid>
          </Grid>
        </form>
      </Paper>
    </div>
  );
}
