import React, { useState } from "react";
import { Select, MenuItem } from "@mui/material";

const RoleRow = ({ row, onRoleChange, disabled }) => {
  const [localRole, setLocalRole] = useState("");

  const handleRoleChange = (event) => {
    setLocalRole(event.target.value);
    onRoleChange(row.id, event.target.value);
  };

  return (
    <Select
      label="Role"
      labelId="role-label"
      value={localRole}
      disabled={disabled}
      onChange={handleRoleChange}
      sx={{ width: "100%" }}>
      <MenuItem value="Admin">Admin</MenuItem>
      <MenuItem value="User">User</MenuItem>
      <MenuItem value="Disabled">Disabled</MenuItem>
      <MenuItem value="">NotSet</MenuItem>
    </Select>
  );
};

export default RoleRow;
