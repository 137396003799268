import { createTheme } from '@mui/material/styles';

const designSystem = createTheme({
  typography: {
    fontFamily: 'Roboto, sans-serif',
    fontSize: 16,
    h1: {
      fontSize: '3.6rem',
      fontWeight: 600,
      lineHeight: 1.2,
    },
    h2: {
      fontSize: '2.8rem',
      fontWeight: 500,
      lineHeight: 1.3,
    },
    h3: {
      fontSize: '2.2rem',
      fontWeight: 500,
      lineHeight: 1.4,
    },
    body1: {
      fontSize: '1.8rem',
    },
    body2: {
      fontSize: '1.6rem',
    },
  },
  palette: {
    primary: {
      main: '#1976D2', // Set your primary color
    },
    secondary: {
      main: '#FF6D00', // Set your secondary color
    },
    background: {
      default: '#F0F2F5',
      paper: '#FFFFFF',
    },
    text: {
      primary: '#333',
      secondary: '#777',
    },
    error: {
      main: '#ff0000', // Red for error messages
    },
    success: {
      main: '#00ff00', // Green for success messages
    },
    warning: {
      main: '#ffcc00', // Yellow for warning messages
    },
    info: {
      main: '#0077cc', // Blue for informational messages
    },
    blueButton: {
      main: '#1976D2', // Define the blue button color
    },
    redButton: {
      main: '#FF3333', // Define the red button color
    },
  },
  spacing: 8,
  shape: {
    borderRadius: 8,
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none', // Button text will be in lowercase
        },
      },
      variants: [
        {
          props: { variant: 'contained', color: 'primary' },
          style: {
            color: 'white', // Text color for primary contained buttons
          },
        },
        {
          props: { variant: 'contained', color: 'blueButton' }, // Define blue button variant
          style: {
            color: 'white', // Text color for blue button
          },
        },
        {
          props: { variant: 'contained', color: 'redButton' }, // Define red button variant
          style: {
            color: 'white', // Text color for red button
          },
        },
      ],
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          margin: '8px 0',
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: 'auto', // Remove the default min-width
        },
      },
    },
  },
});

export default designSystem;
