import React, { useState } from 'react';
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  IconButton,
  AppBar,
  Toolbar,
  useMediaQuery,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import HomeIcon from '@mui/icons-material/Home';
import AdminIcon from '@mui/icons-material/SupervisorAccount';
import BillingIcon from '@mui/icons-material/AccountBalance';
import LogoutIcon from '@mui/icons-material/Logout';
import MenuIcon from '@mui/icons-material/Menu';
import { makeStyles } from '@mui/styles';

const mobileDrawerWidth = '12%';
const desktopDrawerWidth = '12%';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    fontSize: '2.6rem',
  },
  appBar: {
    backgroundColor: '#000 !important',
    zIndex: 1201,
    height: '4.8rem',
    minHeight: '0% !important',
    alignItems: 'center',
    justifyContent: 'space-between',
    display: 'flex',
    width: '100%',
  },
  drawer: {
    flexShrink: 0,
  },
  drawerPaper: {
    marginTop: '48px',
  },
  menuButton: {
    order: 1,
    marginRight: 'auto',
  },
  content: {
    flexGrow: 1,
    padding: 16,
  },
  logo: {
    order: 2,

    textAlign: 'center',
    fontWeight: 'bold',

    width: '40px',
    display: 'flex',
    alignItems: 'center',
  },
  navList: {},
  listItemMobile: {
    minWidth: '100%',
  },
  listItemDesktop: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    gap: '12px',
    minWidth: '0%',
  },
  listItemIcon: {
    minWidth: '0%',
  },
}));
export default function NavigationDrawer() {
  const classes = useStyles();

  const navigate = useNavigate();
  const { isAdmin, logout } = useAuth();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const isMobile = useMediaQuery('(max-width: 960px)');

  const handleLogout = () => {
    logout();
    navigate('/');
    if (isMobile) {
      toggleDrawer();
    }
  };

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  const shouldDisplayText = !isMobile;

  const drawerWidth = isMobile ? mobileDrawerWidth : desktopDrawerWidth;

  const listItems = (
    <List className={classes.navList}>
      <ListItem
        button
        onClick={() => {
          navigate('/users');
          if (isMobile) {
            toggleDrawer();
          }
        }}
        className={isMobile ? classes.listItemMobile : classes.listItemDesktop}>
        <ListItemIcon className={classes.listItemIcon}>
          <HomeIcon />
        </ListItemIcon>
        {shouldDisplayText && <ListItemText primary="Home" />}
      </ListItem>

      {isAdmin() && (
        <>
          <ListItem
            button
            onClick={() => {
              navigate('/admin');
              if (isMobile) {
                toggleDrawer();
              }
            }}
            className={
              isMobile ? classes.listItemMobile : classes.listItemDesktop
            }>
            <ListItemIcon className={classes.listItemIcon}>
              <AdminIcon />
            </ListItemIcon>
            {shouldDisplayText && <ListItemText primary="Admin" />}
          </ListItem>
          <ListItem
            button
            onClick={() => {
              navigate('/billing');
              if (isMobile) {
                toggleDrawer();
              }
            }}
            className={
              isMobile ? classes.listItemMobile : classes.listItemDesktop
            }>
            <ListItemIcon className={classes.listItemIcon}>
              <BillingIcon />
            </ListItemIcon>
            {shouldDisplayText && <ListItemText primary="Billing" />}
          </ListItem>
        </>
      )}

      <ListItem
        button
        onClick={() => {
          handleLogout();
        }}
        className={isMobile ? classes.listItemMobile : classes.listItemDesktop}>
        <ListItemIcon className={classes.listItemIcon}>
          <LogoutIcon />
        </ListItemIcon>
        {shouldDisplayText && <ListItemText primary="Logout" />}
      </ListItem>
    </List>
  );

  return (
    <div className={classes.root}>
      <AppBar position="fixed">
        <Toolbar
          sx={{ height: '48px !important' }}
          className={classes.appBar}>
          {isMobile && (
            <IconButton
              color="inherit"
              onClick={toggleDrawer}
              edge="start"
              aria-label="menu"
              size="large"
              className={classes.menuButton}>
              <MenuIcon />
            </IconButton>
          )}

          <img
            src="/img/base_logo.png"
            alt="Your Logo"
            className={classes.logo}
          />
        </Toolbar>
      </AppBar>

      <Drawer
        sx={{
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
        }}
        variant={isMobile ? 'temporary' : 'permanent'}
        anchor="left"
        open={drawerOpen}
        onClose={toggleDrawer}
        classes={{
          paper: classes.drawerPaper,
        }}>
        {listItems}
      </Drawer>
    </div>
  );
}
