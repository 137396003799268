import React from "react";
import Card from "@mui/material/Card";
import { DataGrid } from "@mui/x-data-grid";

const getStatusLabel = (status) => {
  return status >= 200 && status < 300 ? "Pass" : "Fail";
};

const LogViewer = ({
  logs,
  sortModel,
  filterModel,
  setSortModel,
  setFilterModel,
}) => {
  const translatedLogs = logs.map((log) => ({
    ...log,
    status: getStatusLabel(log.status),
  }));

  return (
    <Card style={{ padding: "10px" }}>
      {logs.length > 1 && (
        <DataGrid
          rows={translatedLogs}
          columns={[
            { field: "time", headerName: "Time", flex: 3 },
            { field: "action", headerName: "Action", flex: 2 },
            { field: "sourceUser", headerName: "Source User", flex: 2 },
            { field: "targetUser", headerName: "Target User", flex: 2 },
            { field: "status", headerName: "Status", flex: 2 },
          ]}
          pageSize={5}
          rowsPerPageOptions={[5]}
          checkboxSelection
          getRowId={(row) => row.id}
          sortModel={sortModel}
          localeText={{
            noRowsLabel: logs.length === 0 ? "Loading..." : "No rows",
          }}
          onSortModelChange={(params) => {
            setSortModel(params.sortModel);
          }}
          filterMode="client"
          disableMultipleColumnsFiltering
          filterModel={filterModel}
          onFilterModelChange={(params) => {
            setFilterModel(params.filterModel);
          }}
          disableRowSelectionOnClick
        />
      )}
    </Card>
  );
};

export default LogViewer;
